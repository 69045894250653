import React, { useEffect, useState } from 'react'
import { Logo } from '../../components/Logo'
import { LogoDesign } from '../../components/LogoDesign'
import { PoweredByComponent } from '../../components/PoweredBy'
import BaseInput from '../../components/baseInput'
import { NavLink, useNavigate, useNavigation} from 'react-router-dom'
import { CONSTANTS, passwordRules } from '../../includes/constant'
import { BaseButton, LightYellowButton } from '../../components/buttons'
import { Formik } from 'formik';
import * as y from 'yup';
import { PostRequest } from '../../includes/functions'
import { error } from 'console'

const schema = y.object({
    email:y.string().required("Email is required").email("A valid email is required."),
    firstName:y.string().required("First name is required"),
    lastName:y.string().required("Last name is required"),
    phoneNumber:y.string().required("Phone number is required"),
    companyAddress:y.string().required("Organisation address is required"),
    businessName:y.string().required("Organisation name is required"),
    password:y.string().required('Password is required.').required('A strong password is required').matches(passwordRules, { message: 'Please create a stronger password.'})
  })
export default function AccessRequestScreen() {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate();
  const SignUp = (values:any)=>{
    setLoading(true);
    PostRequest("business-signup",values,true,true,"upload").then((response)=>{
      setLoading(false);
      if(response.status)
      {
        navigate("/"+CONSTANTS.Routes.Login);
      }
    })
  }
  return (<div className='row'>
    <div className='col-lg-2 col-md-0 col-sm-0' ></div>
    <div className='col-lg-8 col-sm-12 col-md-12 p-5 ' >
    <Formik
onSubmit={(values)=>{
  SignUp(values);
}}
validationSchema={schema}
initialValues={{
 email:"",
 firstName:"",
 lastName:"",
 phoneNumber:"",
 companyAddress:"",
 businessName:"",
 password:""
}}
>
{({handleSubmit,handleChange,values,errors})=><div className='' >
<div className='text-center' >
<img 
   style={{width:200,marginBottom:30}}
   src='https://awabahng.com/static/media/awabah_logo.3f50bd75.svg'
   />
   </div>
       <div className="text-center title-text">Access Request</div>
       <div className="text-center">Fill the form below, we'll verify you and give you access to sandbox.</div>
       <div className='row p-5' >
       <div className='col-2' ></div>
       <div className='col-8' >
       <BaseInput 
       class="text-center"
       name='firstName'
       type='text'
       placeholder='Enter your first name'
       max={30}
       onValueChange={handleChange("firstName")}  
       value={values.firstName}
       required={true}
       error={errors.firstName}
       />
       <BaseInput 
       class="text-center"
       name='lastName'
       type='text'
       placeholder='Enter your last name'
       max={30}
       onValueChange={handleChange("lastName")}  
       value={values.lastName}
       required={true}
       error={errors.lastName}
       />
       <BaseInput 
       class="text-center"
       name='email'
       type='text'
       placeholder='Enter email address'
       max={100}
       onValueChange={handleChange("email")}  
       value={values.email}
       required={true}
       error={errors.email}
       />
       <BaseInput 
       class="text-center"
       name='password'
       type='password'
       placeholder='Enter your password'
       max={100}
       onValueChange={handleChange("password")}  
       value={values.password}
       required={true}
       error={errors.password}
       />
       <BaseInput 
       class="text-center"
       name='phoneNumber'
       type='mobile'
       placeholder='Enter your phone number'
       max={11}
       onValueChange={handleChange("phoneNumber")}  
       value={values.phoneNumber}
       required={true}
       error={errors.phoneNumber}
       />
        <BaseInput 
       class="text-center"
       name='companyAddress'
       type='text'
       placeholder='Enter your organisation name'
       max={11}
       onValueChange={handleChange("companyAddress")}  
       value={values.companyAddress}
       required={true}
       error={errors.companyAddress}
       />
       <BaseInput 
       class="text-center"
       name='businessName'
       type='text'
       placeholder='Enter your organisation address'
       max={11}
       onValueChange={handleChange("businessName")}  
       value={values.businessName}
       required={true}
       error={errors.businessName}
       />
       <div className='row p-2 pe-3' >
       <BaseButton 
       onClick={handleSubmit}
       loading={loading}
       >Continue</BaseButton>
       </div>
       <div className='row'>
       <div className='col-12 mb-5'>
       <NavLink to={"../"+CONSTANTS.Routes.Login} className={"recovery-text"}>
           <span >Go back to <b>Login</b></span>
       </NavLink>
       </div>
       </div>
       </div>
       <div className='col-2' ></div>
       </div>
       </div>}
    </Formik>
      
    </div>
    <div className='col-lg-2 col-md-0 col-sm-0' ></div>
   </div>
 )
}
