import React, { useEffect, useState } from 'react'
import { Logo } from '../../components/Logo'
import { LogoDesign } from '../../components/LogoDesign'
import { PoweredByComponent } from '../../components/PoweredBy'
import BaseInput from '../../components/baseInput'
import { NavLink, useNavigation} from 'react-router-dom'
import { CONSTANTS } from '../../includes/constant'
import { BaseButton, LightYellowButton } from '../../components/buttons'
import { Formik } from 'formik';
import * as y from 'yup';
import { PostRequest } from '../../includes/functions'

const schema = y.object({
    email:y.string().required("Email is required.").email("A valid email is required.")
    })
export default function ResetPasswordScreen() {
  const [loading,setLoading] = useState(false);
  const ForgotPassword = (values:any)=>{
    setLoading(true);
    PostRequest("forgot-password",values,true,true,"upload").then((response)=>{
      setLoading(false);
    })
  }
  return (<div className='row'>
    <div className='col-lg-2 col-md-0 col-sm-0' ></div>
    <div className='col-lg-8 col-sm-12 col-md-12 p-5 ' >
    <Formik
onSubmit={(values)=>{
  ForgotPassword(values)
}}
validationSchema={schema}
initialValues={{
 email:"",
}}
>
{({handleSubmit,handleChange,values,errors})=><div className='' >
<div className='text-center' >
<img 
   style={{width:200,marginBottom:30}}
   src='https://awabahng.com/static/media/awabah_logo.3f50bd75.svg'
   />
   </div>
       <div className="text-center title-text">Forgot password?</div>
       <div className="text-center">Enter your email address, we'll generate a new password and send to your email for your login.</div>
       <div className='row p-5' >
       <div className='col-2' ></div>
       <div className='col-8' >
       <BaseInput 
       class="text-center"
       name='email'
       type='email'
       placeholder='Enter email address'
       max={100}
       onValueChange={handleChange("email")}  
        value={values.email}
       required={true}
       error={errors.email}
       />
       
       
       <div className='row p-2 pe-3' >
       <BaseButton 
       onClick={handleSubmit}
       loading={loading}
       >Continue</BaseButton>
       </div>
       <div className='row'>
       <div className='col-12 mb-5'>
       <NavLink to={"../"+CONSTANTS.Routes.Login} className={"recovery-text"}>
           <span >Go back to <b>Login</b></span>
       </NavLink>
       </div>
       </div>
       </div>
       <div className='col-2' ></div>
       </div>
       </div>}
    </Formik>
      
    </div>
    <div className='col-lg-2 col-md-0 col-sm-0' ></div>
   </div>
 )
}
