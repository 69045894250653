import React, { useEffect, useState } from 'react'
import { NavLink, Navigate, useLocation } from 'react-router-dom'
import { CONSTANTS, DashboardNavItems, DevEnv } from '../../includes/constant'
import {  BusinessIcon, DashboardIcon, EmployeeDataIcon, LogoutIcon, RoleIcon, SettingsIcon, VehicleIcon } from './icon'
import './style.css';
import { BaseLoader } from '../../components/baseloader'
import { GetRequest } from '../../includes/functions'
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

export default function DashboardScreen() {
  const [loading,setLoading] = useState(false);

  const GetUserInfo = ()=>{
   GetRequest("admin",{}).then((response)=>{
     if(response.status)
     {
      localStorage.setItem("userdata",JSON.stringify(response.data))
     }
   })
 }
 useEffect(()=>{
   // GetUserInfo();
 },[])
  if(!localStorage.getItem(CONSTANTS.Routes.apis))
  {
   return <Navigate to={"/"} />
  }

  return (<div className='row'>
     <div className='col-2 sidemenu position-relative .d-none' >
     <div className='p-5 ' >
      <div className='d-flex align-items-center'>
      <img 
      style={{width:140}}
      src="https://awabahng.com/static/media/awabahLogo.0e8b9750.svg"
      />
      </div>
        <ul className='sub Dashboard-items'>
        {DashboardNavItems.map((a,i)=>{
         return <NavBtn title={a.title} id={i} link={a.link} key={i} />
        })}
        </ul>
        </div>
     </div>
     <div className='col-9 mmain' style={{}} >
     <div className='' >
     <div className='row p-3' >
        <div className='col-10' >
        <div className='lg-hide' >
        </div>
        <div className="heading mb-5 m-hide lg-show fs-3"><b className='fCap'>API Documentation</b></div>
        </div>
        <div className='col-2 d-flex align-Item-end justify-content-end' >
        <span className='btn'>
         <NavLink to={"/"} >
            <SettingsIcon color='gray' />
         </NavLink>
        </span>
        <span className='btn'
        onClick={()=>{
            if(!loading)
            {
            setLoading(true);
            setTimeout(()=>{
               localStorage.clear();
               window.location.reload();
            },200)
            }
        }}
        >
        {loading?<BaseLoader />:<LogoutIcon color='gray' />}
        </span>
        </div>
     </div>
     <SwaggerUI url={DevEnv?`${CONSTANTS.BaseURL}swagger`:"https://sandbox.awabah.com/v1/swagger"} />
     </div>
     </div>
    </div>
  )
}
interface  NavBtnProps {
id?:number
link:string;
title:string;
}

const NavBtn = (props:NavBtnProps)=>{
 const path = window.location.pathname
 const link = props.link;
 const ActiveTab = path === link;
 var icon =  <DashboardIcon color={ActiveTab?"rgba(248, 176, 43, 1)":"rgba(138, 139, 142, 1)"} />; 
 if(props.id === 0)
 {
    icon = <DashboardIcon color={ActiveTab?"rgba(248, 176, 43, 1)":"rgba(138, 139, 142, 1)"} />; 
 }
 if(props.id === 1)
   {
      icon = <BusinessIcon color={ActiveTab?"rgba(248, 176, 43, 1)":"rgba(138, 139, 142, 1)"} /> 
   } 
   if(props.id === 2)
   {
      icon = <EmployeeDataIcon color={ActiveTab?"rgba(248, 176, 43, 1)":"rgba(138, 139, 142, 1)"} /> 
   }
   if(props.id === 3)
   {
      icon = <VehicleIcon color={ActiveTab?"rgba(248, 176, 43, 1)":"rgba(138, 139, 142, 1)"} /> 
   }
   if(props.id === 4)
   {
      icon = <RoleIcon color={ActiveTab?"rgba(248, 176, 43, 1)":"rgba(138, 139, 142, 1)"} /> 
   }
  return <li >
   <NavLink to={props.link} 
  className={ActiveTab?"active" : 'inactive'} 
   ><span>{icon}</span><span >{props.title}</span></NavLink></li>
}
