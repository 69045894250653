import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { CONSTANTS } from './includes/constant';
import LoginScreen from './screens/login';
import ResetPasswordScreen from './screens/reset_password';
import AccessScreen from './screens/access_request';
import DashboardScreen from './screens/dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (<BrowserRouter>
    <Routes>
    <Route path={CONSTANTS.Routes.apis} element={<DashboardScreen />} />
    <Route path={CONSTANTS.Routes.Login} element={<LoginScreen />} />
    <Route path={CONSTANTS.Routes.ForgotPassword} element={<ResetPasswordScreen />} />
    <Route path={CONSTANTS.Routes.access_request} element={<AccessScreen />} />
    <Route path={"*"} element={<LoginScreen />} />
    </Routes>
    <ToastContainer />
    </BrowserRouter>);
}
   
export default App;
