export const DevEnv = false;

export const CONSTANTS = {
  BaseURL:DevEnv?"http://localhost/awabah-api/v1/backend/":"https://sandbox.awabah.com/v1/",
  Routes:{
      Login:"login",
      ForgotPassword:"forgot_password",
      apis:"apis",
      access_request:"access_request"
    },
  
}
export const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*]).{8,20}$/;

interface ListProps {
icon?:JSX.Element;
title:string;
link:string;
}
export const DashboardNavItems:ListProps[] = [
] 