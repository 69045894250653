import React, { useState } from 'react'
import BaseInput from '../../components/baseInput'
import { Navigate, NavLink, useNavigate } from 'react-router-dom'
import { CONSTANTS } from '../../includes/constant'
import { BaseButton } from '../../components/buttons'
import { Formik} from 'formik';
import * as y from 'yup';
import { PostRequest } from '../../includes/functions';

const schema = y.object({
    email:y.string().required("Email is required").email("A valid email is required."),
    password:y.string().required("Password is required")
    })
export default function LoginScreen() {
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate()
  const UserLogin = (values:any)=>{
    setLoading(true);
    PostRequest("business-login",values,true,true,"upload").then((response)=>{
      setLoading(false);
      if(response.status)
      {
       navigate("/"+CONSTANTS.Routes.apis);
      }
    })
  }
  if(localStorage.getItem(CONSTANTS.Routes.apis))
    {
     return <Navigate to={"/"+CONSTANTS.Routes.apis} />
    }
  return (<div className='row'>
     <div className='col-lg-2 col-md-0 col-sm-0' ></div>
     <div className='col-lg-8 col-sm-12 col-md-12 p-5 ' >
     <Formik
onSubmit={(values)=>{
UserLogin(values)
}}
validationSchema={schema}
initialValues={{
  email:"",
  password:""
}}
>
{({handleSubmit,handleChange,values,errors})=><div className='' >
<div className='text-center' >
<img 
    style={{width:200,marginBottom:30}}
    src='https://awabahng.com/static/media/awabah_logo.3f50bd75.svg'
    />
    </div>
        <div className="text-center title-text">Welcome to Awabah 😊</div>
        <div className="text-center">Let's pick things up from where you left it</div>
        <div className='row p-5' >
        <div className='col-2' ></div>
        <div className='col-8' >
        <BaseInput 
        class="text-center"
        name='email'
        type='email'
        placeholder='Enter email address'
        max={100}
        onValueChange={handleChange("email")}  
        value={values.email}
        required={true}
        error={errors.email}
        />
         <BaseInput 
         class="text-center"
        name='password'
        type='password'
        placeholder='Password'
        max={50}
        onValueChange={handleChange("password")} 
        value={values.password}
        required={true}
        error={errors.password}
        />
        <div className='row'>
        <div className='col-12 mb-5'>
        <NavLink to={"../"+CONSTANTS.Routes.ForgotPassword} className={"recovery-text"}>
            <span >Forgot password? <b>Recover</b></span>
        </NavLink>
        </div>
        </div>
        <div className='row p-2 pe-3' >
        <BaseButton 
        onClick={handleSubmit}
        loading={loading}
        >Login</BaseButton>
        </div>
        <div className='row'>
        <div className='col-12 mb-5 mt-3'>
        <NavLink to={"../"+CONSTANTS.Routes.access_request} className={"recovery-text text-center"}>
            <span >Request for <b>access</b></span>
        </NavLink>
        </div>
        </div></div>
        <div className='col-2' ></div>
        </div>
        </div>}
     </Formik>
       
     </div>
     <div className='col-lg-2 col-md-0 col-sm-0' ></div>
    </div>
  )
}